<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind:class="`${dStatus.class}--text mr-2 icon__size-50`"
          v-on="on"
          v-bind="attrs"
        >
          {{ dStatus.icon }}
        </v-icon>
      </template>
      <span>{{ `${$t("tooltip.draft-is-" + dStatus.label)}` }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  mdiCheckboxBlankOutline,
  mdiEmailEditOutline,
  mdiLockClock,
  mdiCogSync,
  mdiEmailArrowRightOutline,
} from "@mdi/js";
import { mapState } from "vuex";
export default {
  name: "header-right",
  data: () => ({
    iconLock: mdiLockClock,
    iconEdit: mdiEmailEditOutline,
    iconCog: mdiCogSync,
    iconSent: mdiEmailArrowRightOutline,
    iconBlank: mdiCheckboxBlankOutline,
  }),
  props: {
    singleDraft: {
      type: Object,
    },
  },
  computed: {
    ...mapState("user", ["currentUser"]),
    currentUserType() {
      if (!this.currentUser) return undefined;
      return this.currentUser.usertype_elemen;
    },
    dStatus: {
      get() {
        if (!this.singleDraft) {
          return {
            class: "grey",
            label: "none",
            icon: mdiCheckboxBlankOutline,
          };
        }
        return {
          class: "success",
          label: "submitted",
          icon: mdiEmailArrowRightOutline,
        };
      },
      set(val) {
        return val;
      },
    },
  },
};
</script>