<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              v-on:menuAction="menuAction"
              v-bind:actionMenu="actionMenu"
              v-bind:creatorCanEdit="creatorCanEdit"
              v-bind:heading="heading"
            />
            <header-right
              slot="right-toolbar"
              v-on:reloadThis="reloadThis"
              v-bind:singleDraft="singleDraft"
            />
            <v-card-text v-if="!singleDraft && !axiosLoading && !networkError">
              <p class="align-center center-align">{{ $t("text.draft-detail-empty") }}</p>
            </v-card-text>
            <v-card-text v-if="networkError || (axiosLoading && !singleDraft)">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-three-line, image, actions"
              ></v-skeleton-loader>
            </v-card-text>

            <v-card-text class="pa-0" v-if="singleDraft">
              <v-col cols="12" class="pa-0">
                <v-tabs color="blue" class="px-4" v-model="myTab">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#tab-1" v-if="singleDraft && singleDraft.memo.memo_status_id != 100">
                    <v-icon class="mr-2">{{ iconEdit }}</v-icon>
                    {{ $t("tab.draft") }}
                  </v-tab>
                  <v-tab href="#tab-1" v-if="singleDraft && singleDraft.memo.memo_status_id == 100">
                    <v-icon class="mr-2">{{ iconMailOpen }}</v-icon>
                    {{ $t("tab.message") }}
                  </v-tab>
                  <v-tab href="#tab-2">
                    <v-icon class="mr-2">{{ iconHistory }}</v-icon>
                    {{ $t("tab.history") }}
                  </v-tab>
                  <v-tab href="#tab-3" v-if="singleDraft.memo_ref">
                    <v-icon class="mr-2">{{ iconInbox }}</v-icon>
                    {{ $t("tab.reference") }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="myTab" touchless>
                  <v-tab-item value="tab-1">
                    <v-container fluid>
                      <v-text-field
                        v-bind:data-hint="$t('introjs.this-is-memo-subject')"
                        class="my-4 memo__revision--title"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        v-model="hal"
                        hide-details="true"
                        maxlength="250"
                        v-bind:hint="
                          $t('tooltip.min-max-char-for-memo-subject')
                        "
                        v-bind:label="$t('label.memo-subject')"
                        v-bind:disabled="!creatorCanEdit"
                      ></v-text-field>
                       
                       <v-text-field v-if="isSuratMasuk"
                       class="memo__revision--title"
                        v-model="lembagaPengirim"
                        v-bind:disabled="!creatorCanEdit"
                         v-bind:label="$t('label.sender institution')"
                       >
                       </v-text-field>

                      <froala-editor
                        v-bind:data-hint="$t('introjs.this-is-memo-message')"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        v-bind:oldEditorData="isi"
                        v-on:editorData="changeEditorData"
                        v-on:froalaIsWorking="froalaIsWorking"
                        v-bind:editorDisabled="!creatorCanEdit"
                      ></froala-editor>
                      <v-col cols="12 table-responsive-container">
                        <div
                          class="attachment-list mt-4 table-responsive"
                          v-if="
                            (attachments && attachments.length > 0) ||
                            (currAttachments && currAttachments.length > 0)
                          "
                        >
                          <p class="mb-1 text--avore-grey" v-html="$t('text.new-attachment-list')"></p>
                          <ul>
                            <li
                              v-for="(item, index) in currAttachments"
                              v-bind:key="index"
                              v-bind:value="item.id"
                              class="file--list"
                            >
                              <a href="javascript:;" class="server--file">
                                <span>{{ index + 1 }}.</span>

                                {{ item.file_name }} - {{ item.file_size | formatSize }}
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="ml-2"
                                      color="blue"
                                      v-on:click="downloadAttachment(item)"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDownload }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{
                                      $t("tooltip.download-this-file")
                                    }}
                                  </span>
                                </v-tooltip>

                                <v-tooltip
                                  bottom
                                  v-if="
                                    !['PJP', 'PJP_KANPUS', 'DIREKTUR'].includes(
                                      currentUserType
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="revisionStatus === 'pending'"
                                      icon
                                      color="red"
                                      v-on:click="
                                        confirmDeleteAttachment(item, index)
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDelete }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{
                                      $t("tooltip.delete-this-file")
                                    }}
                                  </span>
                                </v-tooltip>
                              </a>
                            </li>
                            <li
                              v-for="(item, idx) in attachments"
                              v-bind:key="item.id"
                              v-bind:value="item.id"
                              class="file--list"
                            >
                              <a href="javascript:;" class="server--file">
                                >
                                <span>{{ idx + 1 + currAttachments.length }}.</span>
                                {{ item.name }} - {{ item.size | formatSize }}
                                <span
                                  v-if="item.error"
                                >{{ item.error }}</span>
                                <span v-else-if="item.success">success</span>
                                <span v-else-if="item.active">active</span>
                                <span v-else></span>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="ml-2"
                                      color="red"
                                      v-on:click="deleteAttachment(item)"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDelete }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{
                                      $t("tooltip.delete-this-file")
                                    }}
                                  </span>
                                </v-tooltip>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </v-col>
                    </v-container>
                    <v-row v-if="!networkError || (!axiosLoading && singleDraft)">
                      <v-col
                        cols="12"
                        class="d-flex justify-end"
                        v-if="currentUserType != 'KRANI' && creatorCanEdit"
                      >
                        <v-tooltip
                          top
                          v-if="
                            !['PJP', 'PJP_KANPUS', 'DIREKTUR'].includes(
                              currentUserType
                            )
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <file-upload
                              input-id="edit-draft"
                              v-on="on"
                              v-bind="attrs"
                              class="att-button v-size--default ma-2 v-btn v-btn--outlined v-size--default blue--text text--lighten-2"
                              post-action="/upload/post"
                              extensions="jpg,jpeg,png,pdf,xls,xlsx,doc,docx"
                              accept="image/png, image/jpeg, application/pdf"
                              v-bind:multiple="true"
                              v-bind:size="1"
                              v-bind:active="true"
                              v-bind:maximum="3"
                              v-model="attachments"
                              v-on:input-filter="inputFilter"
                              v-on:input-file="inputFile"
                              v-bind:title="$t('tooltip.add-attachments')"
                              ref="upload"
                            >
                              <v-icon class="blue--text text--lighten-2">
                                {{
                                  iconAttachment
                                }}
                              </v-icon>
                            </file-upload>
                          </template>
                          <span>{{ $t("tooltip.add-attachments") }}</span>
                        </v-tooltip>

                        <v-menu top left>
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  data-hintposition="middle-middle"
                                  class="ma-2"
                                  outlined
                                  color="blue lighten-2"
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon class="mr-2">{{ iconCog }}</v-icon>
                                  {{ $t("button.actions") }}
                                </v-btn>
                              </template>
                              <span>{{ $t("tooltip.select-action") }}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, i) in actionMenu"
                              :key="i"
                              @click="menuAction(item.command)"
                            >
                              <v-icon class="mr-3">{{ item.icon }}</v-icon>
                              <v-list-item-title>
                                {{
                                  $t(item.title)
                                }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <history-detail></history-detail>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item value="tab-3" v-if="singleDraft.memo_ref">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <reference-detail v-bind:singleMemo="singleDraft"></reference-detail>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-card-text>
            <hr class="card-footer" />
            <v-card
              elevation="0"
              class="mt-2 d-flex justify-end no-box-shadow no-border v-sheet--outlined"
            >
            </v-card>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>

    <vue-html2pdf
      v-bind:show-layout="controlValue.showLayout"
      v-bind:float-layout="controlValue.floatLayout"
      v-bind:enable-download="controlValue.enableDownload"
      v-bind:preview-modal="controlValue.previewModal"
      v-bind:filename="controlValue.filename"
      v-bind:paginate-elements-by-height="controlValue.paginateElementsByHeight"
      v-bind:pdf-quality="controlValue.pdfQuality"
      v-bind:pdf-format="controlValue.pdfFormat"
      v-bind:pdf-orientation="controlValue.pdfOrientation"
      v-bind:pdf-content-width="controlValue.pdfContentWidth"
      v-bind:manual-pagination="controlValue.manualPagination"
      v-bind:html-to-pdf-options="htmlToPdfOptions"
      v-on:progress="onProgress($event)"
      v-on:startPagination="startPagination()"
      v-on:hasPaginated="hasPaginated()"
      v-on:beforeDownload="beforeDownload($event)"
      v-on:hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <memo-print-preview
        v-on:domRendered="domRendered()"
        v-bind:singleMemo="singleDraft"
        v-bind:recipientSide="false"
        slot="pdf-content"
      />
    </vue-html2pdf>

    <peer-selector
      v-bind:showPeerDialog="showPeerDialog"
      v-on:showHidePeerDialog="showHidePeerDialog"
      v-on:peerSelected="peerSelected"
    ></peer-selector>

    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
      v-bind:submitOptions="submitOptions"
    ></insert-comment>
    <memo-validation-modal :singleMemo="singleDraft"></memo-validation-modal>
    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import HistoryDetail from "./HistoryDetail";
import ReferenceDetail from "./ReferenceDetail";
import peerSelector from "@/components/commonComponents/PeerSelector.vue";
import insertComment from "@/components/commonComponents/InsertComment.vue";
import { isIntroActive } from "@/constants/config";
import dayjs from "dayjs";
import froalaEditor from "@/components/commonComponents/FroalaEditor";
import "intro.js/minified/introjs.min.css";
import { http } from "@/http/http";
import VueUploadComponent from "vue-upload-component";
import toolbarMenu from "@/constants/toolbarmenu";
import MemoPrintPreview from "@/components/commonComponents/MemoPrintPreview.vue";
import VueHtml2pdf from "vue-html2pdf";
import { mapFields } from "vuex-map-fields";
import memoValidationModal from "@/components/commonComponents/MemoValidationModal";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiPen,
  mdiHistory,
  mdiContentSaveOutline,
  mdiDotsHorizontalCircleOutline,
  mdiDeleteAlertOutline,
  mdiCloudDownloadOutline,
  mdiEmailOpenOutline,
  mdiCog,
  mdiEmailArrowRightOutline,
  mdiContentDuplicate,
} from "@mdi/js";

export default {
  components: {
    HeaderLeft,
    HeaderRight,
    HistoryDetail,
    ReferenceDetail,
    peerSelector,
    insertComment,
    froalaEditor,
    "file-upload": VueUploadComponent,
    VueHtml2pdf,
    MemoPrintPreview,
    memoValidationModal,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconHistory: mdiHistory,
    iconEdit: mdiPen,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSave: mdiContentSaveOutline,
    iconMenu: mdiDotsHorizontalCircleOutline,
    iconDelete: mdiDeleteAlertOutline,
    iconDownload: mdiCloudDownloadOutline,
    iconMailOpen: mdiEmailOpenOutline,
    iconInbox: mdiEmailArrowRightOutline,
    iconCog: mdiCog,
    iconDuplicate: mdiContentDuplicate,
    myTab: 0,
    timeout: null,
    comment: null,
    showPeerDialog: false,
    showCommentDialog: false,
    editorDisabled: false,
    attachments: undefined,
    currAttachments: [],
    selectedPeers: undefined,
    hal: null,
    isi: null,
    submitOptions: undefined,
    actionMenu: toolbarMenu.revisionDetailORSubmitOnlyMenu,
    overlay: false,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("draft", ["axiosLoading", "singleDraft"]),
    ...mapFields("pdfgenerator", ["controlValue"]),
    userIsPjpDirSekretaris() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS", "DIREKTUR", "SEKRETARIS"].includes(
        this.currentUser.usertype_elemen
      );
    },
    isSuratMasuk(){
      //if status is sent and isMasuk = true show the label;
      if (!this.singleDraft) return false;
      return parseInt(this.singleDraft.memo.is_masuk) === 1 && parseInt(this.singleDraft.memo.memo_status_id) === 100;
    },
    lembagaPengirim(){
    if (!this.singleDraft) return '';
    return this.singleDraft.memo.lembaga_pengirim_surat;
    },
    htmlToPdfOptions() {
      return {
        margin: 2,
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
        filename: this.singleDraft
          ? this.strCut(this.singleDraft.memo.hal, 100) + ".pdf"
          : "undefined.pdf",
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "cm",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
    currentUserType() {
      if (!this.currentUser) return undefined;
      return this.currentUser.usertype_elemen;
    },
    creatorCanEdit() {
      return this.$route.params.canEdit;
    },
    revisionStatus() {
      let result = { status: "unknown", class: "grey", icon: this.iconBlank };

      if (!this.singleRevision || !this.currentUserType) return result;

      let status = this.draftStatus({
        hasRead: this.singleRevision.memo.updated_at,
        memoStatus: parseInt(this.singleRevision.memo.memo_status_id),
        revisionStatus: parseInt(
          this.singleRevision.revision.revision_status_id
        ),
        revisionTypeId: parseInt(this.singleRevision.revision.revision_type_id),
        currentUserType: this.currentUserType,
      });

      switch (status) {
        case "submitted":
          result = {
            status: "submitted",
            class: "success",
            icon: this.iconSent,
          };
          break;
        case "pending":
          result = { status: "pending", class: "warning", icon: this.iconEdit };
          break;
        case "locked":
          result = { status: "locked", class: "error", icon: this.iconLock };
          break;
        case "terminated":
          result = {
            status: "terminated",
            class: "error",
            icon: this.iconTerminated,
          };
          break;
        default:
          break;
      }

      return result;
    },
    heading() {
      if (this.$route.name === "sent.detailmac")
        return this.$t("card.sent-detail-title");
      return this.$t("card.draft-detail-title");
    },
  },
  created() {
    this.reloadThis();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    singleDraft(val) {
      if (!val) return;
      this.currAttachments = val.memo_attachments;
      this.hal = val.memo.hal;
      this.isi = val.memo.isi;
    },
  },
  methods: {
    ...mapActions("draft", [
      "setAxiosLoading",
      "terminateThisDraft",
      "loadDraftDetail",
      "deleteServerAttachment",
      "shareMyRevision",
      "setDraftHistory",
      "getDraftHistory",
      "setDraftInfo",
      "submitRevisionAsPeer",
      "submitMyRevision",
      "submitMyDraft",
      "loadTerminatedDetail",
      "loadRevokedDetail",
      "revokeMemo"
    ]),
    ...mapActions("sent", ["makeCopyAsNewDraft"]),
    reloadThis() {
      this.setDraftHistory(undefined);
      this.setDraftInfo(undefined);
      if (this.$route.name === "terminated.detailmac") {
        this.loadTerminatedDetail({ memoId: this.$route.params.id });
      } else if (this.$route.name === "terminated.detailmac") {
        this.loadTerminatedDetail({ memoId: this.$route.params.id });
      } else {
        this.loadDraftDetail({ memoId: this.$route.params.id });
      }
      this.getDraftHistory({ rowId: this.$route.params.id, tableName : 'trx_memos' });
    },
    showHidePeerDialog(val) {
      this.showPeerDialog = val;
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    peerSelected(val) {
      if (!val) return;
      this.selectedPeers = val;
      this.shareThis();
    },
    changeEditorData(val) {
      this.isi = val;
    },
    froalaIsWorking() { },
    menuAction(val) {
      switch (val) {
        case "home":
          this.$router.push({ name: "draft" });
          break;
        case "home_sent":
          this.$router.push({ name: "sent" });
          break;
        case "home_sentx":
          this.$router.push({ name: "sentx" });
          break;
        case "reload":
          this.reloadThis();
          break;
        case "save":
          this.saveThis();
          break;
        case "share_to_revision":
          this.shareThis();
          break;
        case "submit":
          this.submitThis();
          break;
        case "submit_as_peer":
          this.submitThisAsPeer();
          break;
        case "terminate_draft":
          this.terminateThis();
          break;
        case "validation":
          this.terminateThis();
          break;
        case "revoke":
          this.revokeThis();
          break;
        case "print":
          this.updateOverlay(true);
          setTimeout(() => {
            this.downloadPdf();
          }, 200);
          break;
        default:
          break;
      }
    },
    saveThis() {
      //update save as draft for me
      let formData = new FormData();

      formData.append("hal", this.hal);
      formData.append("isi", this.isi);
      formData.append("revision_status", 1);
      formData.append("action", "save");

      if (this.attachments && this.attachments.length > 0) {
        for (const [key, file] of Object.entries(this.attachments)) {
          formData.append(`attachments[${key}]`, file.file);
        }
      }

      this.submitMyDraft({
        memoId: this.singleDraft.memo.id_hash,
        formData: formData,
      });

      this.$nextTick(() => {
        this.$router.replace({ name: "revision" });
      });
    },

    mapId(obj) {
      if (!obj || obj.length === 0) return [];
      return obj.map(({ id }) => id);
    },

    // shareThis() {
    //   //open peer selector
    //   if (!this.selectedPeers || this.selectedPeers.length < 1)
    //     return this.showHidePeerDialog(true);
    //   //  'reviser_nik' => 'required|string', //comma delimiter
    //   // 'hal' => 'nullable|string',
    //   // 'isi' => 'nullable|string',

    //   // console.log('share to peers');
    //   let strNik = this.selectedPeers.map(({ nik }) => nik);

    //   let formData = new FormData();

    //   formData.append("reviser_nik", strNik.join()),
    //     formData.append("hal", this.hal);
    //   formData.append("isi", this.isi);
    //   formData.append("revision_status", 2);

    //   if (this.attachments && this.attachments.length > 0) {
    //     for (const [key, file] of Object.entries(this.attachments)) {
    //       formData.append(`attachments[${key}]`, file.file);
    //     }
    //   }

    //   this.shareMyRevision({
    //     memo_id: this.singleDraft.memo.id_hash,
    //     formData: formData,
    //   });

    //   this.$nextTick(() => {
    //     this.$router.replace({ name: "revision" });
    //   });
    // },

    submitThis() {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-submit-this-revision"),
          title: this.$t("msgbox.submit-revision"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "red", key: false },
            { text: this.$t("button.yes-submit"), color: "primary", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = new FormData();

          formData.append("hal", this.hal);
          formData.append("isi", this.isi);
          formData.append("revision_status", 2);

          if (this.attachments && this.attachments.length > 0) {
            for (const [key, file] of Object.entries(this.attachments)) {
              formData.append(`attachments[${key}]`, file.file);
            }
          }

          this.submitMyRevision({
            memoId: this.singleDraft.memo.id_hash,
            formData: formData,
          });

          this.$nextTick(() => {
            this.$router.replace({ name: "revision" });
          });
        });
    },
     commentFilled(val) {
      if (!val) return;
      this.comment = val;
      if (this.submitOptions.mode === "terminated") this.terminateThis();
      if (this.submitOptions.mode === "revoke") this.revokeThis();
    },
    revokeThis() {
      if (!this.comment) {
        this.submitOptions = {
          mode: "revoke",
          btnText: "button.revoke",
          btnIcon: this.iconRevoke,
          commentDesc: this.$t("text.please-fill-revoke-comment-or-reason"),
        };
        return (this.showCommentDialog = true);
      }
      let formData = {
        reason: this.comment,
        direct_revoke: 1
      };
      this.revokeMemo({
        memoId: this.singleDraft.memo.id_hash,
        formData: formData,
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.$router.replace({ name: "sent" });
        }, 500);
      });
    },
    terminateThis() {
      // return   this.$dialog.error({
      //     text: this.$t("msgbox.cannot-terminate-more-than-one-draft-at-a-time"),
      //     title: this.$t("msgbox.terminate-draft"),
      //     persistent: true,
      //   });

      if (!this.comment) {
        this.submitOptions = {
          btnText: "button.terminate",
          btnIcon: this.iconDelete,
          commentDesc: this.$t(
            "text.please-fill-termination-comment-or-reason"
          ),
        };
        return (this.showCommentDialog = true);
      }

      let formData = {
        params: this.singleDraft.memo.id_hash,
        comment: this.comment,
      };
      this.terminateThisDraft({
        revisionId: this.singleDraft.memo.id_hash,
        formData: formData,
      });
      this.$nextTick(() => {
        this.$router.replace({ name: "revision" });
      }, 500);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx)$/i.test(newFile.name)) {
          this.$dialog.error({
            text: this.$t("msgbox.only-accept-these-file-extension"),
            title: this.$t("msgbox.add-attachments"),
            persistent: true,
            actions: [
              { text: this.$t("button.ok"), color: "blue", key: false },
            ],
          });
          return prevent();
        }

        if (newFile.file.size > 10000000) {
          this.setMessageBox({
            snackbar: false,
            show: true,
            type: "error",
            title: "error.error-title",
            message: "error.attachment-size-is-too-big",
          });

          return prevent();
        }
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        // console.log("add", newFile);
        if (this.attachments.length === 1) {
          /* this.setMessageBox({
            snackbar: true,
            show: true,
            type: "info",
            title: "msgbox.info-title",
            message: "msgbox.attachment-will-upload-on-submit-or-save",
          }); */

          return this.$dialog.notify.success(
            this.$t("msgbox.attachment-will-upload-on-submit-or-save"),
            {
              position: "top-left",
              timeout: 5000,
            }
          );
        }
      }
      if (newFile && oldFile) {
        // update
        console.log("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        //send to backend to remove by file_id
        console.log("delete", oldFile);
        //this.deleteAttachment(oldFile);
      }
    },
    confirmDeleteAttachment(file, index) {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-this-attachment"),
          title: this.$t("msgbox.delete-attachment"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = {
            memoId: this.singleDraft.memo.id_hash,
            id_hash: file.id_hash,
            _method: "DELETE",
          };
          this.deleteServerAttachment(formData).then((result) => {
            if (result.data == true) {
              this.currAttachments.splice(index, 1);
            }
          });
        });
    },
    deleteAttachment(file) {
      if (!file) return;
      this.$refs.upload.remove(file);
    },
    downloadAttachment(file) {
      if (!file) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.setAxiosLoading(true);

      http
        .get(`/memo/${file.memo_id}/attachments/${file.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return;
          if (response) {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", file.file_name);
            document.body.appendChild(fileLink);

            this.setAxiosLoading(false);
            fileLink.click();
          }
        });
    },
    async downloadPdf() {
      window.scrollTo(0, 0);
      this.updateOverlay(true);
      try {
        const response = await http.get(
          `/memo/myrevision/${this.singleDraft.memo.id_hash}/getpdf`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            responseType: "blob",
          }
        )
        var file = new Blob([response], { type: 'application/pdf' });
        var url = URL.createObjectURL(file);
        window.open(url, '', 'width=800,height=768');
      }
      catch (error) {
        console.error(error);
        this.updateOverlay(false);
      }
      finally {
        this.updateOverlay(false);
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert("pdf-quality value should only be 0 - 2");
        this.controlValue.pdfQuality = 2;
        return false;
      }
      if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;
        return false;
      }
      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];
      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = "a4";
        return false;
      }
      if (!this.controlValue.pdfOrientation) {
        alert("pdf-orientation value cannot be empty");
        this.controlValue.pdfOrientation = "portrait";
        return false;
      }
      if (!this.controlValue.pdfContentWidth) {
        alert("pdf-content-width value cannot be empty");
        this.controlValue.pdfContentWidth = "800px";
        return false;
      }
      return true;
    },
    onProgress(progress) {
      this.progress = progress;
      console.info(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      // console.log(`On Before PDF Generation`, html2pdf, options, pdfContent);
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },
    hasDownloaded() {
      this.pdfDownloaded = true;
      this.updateOverlay(false);
    },
    domRendered() {
      this.contentRendered = true;
    },
    onBlobGenerate() { },
    updateOverlay(val) {
      this.overlay = val;
    },
    copyAsNewDraft() {
      this.makeCopyAsNewDraft({ memoId: this.$route.params.id });
    },
  },
};
</script>