<template>
   <div>
     <v-row>
       <v-col md="6" sm="12" class="d-flex flex-column">
         <v-card class="flex" outlined tile>
           <v-list-item two-line>
             <v-list-item-content>
               <v-list-item-title class="headline">
                 {{ $t("card.detail-info") }}
                 <span
                   v-if="singleDraft.memo.tipe === 'izin_prinsip'"
                   class="error--text"
                   >({{ $t("label.permit-memo") }})</span
                 >
                 <span
                   v-if="singleDraft.memo.tag.includes('rahasia')"
                   class="error--text"
                   >({{ $t("label.secret-memo") }})</span
                 >
                 <span
                   v-if="singleDraft.memo.tipe === 'bom'"
                   class="error--text"
                   >({{ $t("label.bom-memo") }})</span
                 >
                 <span
                   v-if="singleDraft.memo.tipe === 'masuk'"
                   class="error--text"
                   >({{ $t("label.memo-from-external") }})</span
                 >
                 <span
                   v-if="singleDraft.memo.tipe === 'eksternal'"
                   class="error--text"
                   >({{ $t("label.external-letter") }})</span
                 >
                 <span
                   v-if="singleDraft.memo.tag.includes('urgent')"
                   class="secondary--text text-smaller"
                   >Mendesak</span
                 >
                 <span
                   v-if="parseInt(singleDraft.memo.approver_valid) !== 1"
                   class="error--text text-smaller"
                   >({{ $t("label.approver-has-changed") }})</span
                 >
               </v-list-item-title>
               <v-list-item-subtitle
                 class="text--darken-1 text-bolder"
                 :class="{
                   'red--text text-uppercase': isTerminated(
                     singleDraft.memo.memo_status_id
                   ),
                   'blue--text': !isTerminated(
                     singleDraft.memo.memo_status_id
                   ),
                 }"
               >
                 <v-icon
                   class="text--lighten-1"
                   :class="{
                     'red--text text-uppercase': isTerminated(
                       singleDraft.memo.memo_status_id
                     ),
                     'blue--text': !isTerminated(
                       singleDraft.memo.memo_status_id
                     ),
                   }"
                   >{{ iconInfo }}</v-icon
                 >
                 {{ singleDraft ? singleDraft.memo.memo_status_label : "" }}
                 <span
                   v-if="isTerminated(singleDraft.memo.memo_status_id)"
                   class="d-block ml-7"
                   >{{
                     `${$t("text.terminated-by")} ${
                       singleDraft.memo.nama_reviser_terakhir
                     }`
                   }}</span
                 >
               </v-list-item-subtitle>
             </v-list-item-content>
           </v-list-item>
           <v-card-text>
             <div class="table-responsive-container">
               <div class="table-responsive info__container">
                 <table class="draft__info-table">
                  <tr v-if="singleDraft.memo.tipe === 'masuk'">
                     <td>{{ $t("label.sender") }}</td>
                     <td>:</td>
                     <td class="recipient__column" style="font-weight: 600">
                       {{ singleDraft.memo.lembaga_pengirim_surat }}
                     </td>
                   </tr>
                   <tr v-if="singleDraft.memo.tipe !== 'eksternal'">
                     <td>{{ $t("label.recipients") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                       <v-chip
                         v-for="item in singleDraft.penerima"
                         :key="item.id"
                         class="mr-1 mb-1 recipient__chip"
                       >
                        {{item.nama}}
                       </v-chip>
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.letter-subject") }}</td>
                     <td>:</td>
                     <td class="recipient__column" style="font-weight: 600">
                       {{ singleDraft.memo.hal }}
                     </td>
                   </tr>
                   <tr v-if="singleDraft.memo.tipe === 'eksternal'">
                     <td>{{ $t("label.recipients") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                        <ol>
                        <li v-for="(item,index) in singleDraft.penerima" :key="index">
                          <span style="display: block; width: 100%;">{{ item }}</span>
                        </li>
                      </ol>
                      <span  v-if="parseInt(singleDraft.memo.kepada_alamat) === 2" style="padding-left: 1.8rem;">
                           Di&nbsp;{{ singleDraft.memo.kepada_line_2 }}
                        </span>
                       <br />
                       <span  v-if="parseInt(singleDraft.memo.kepada_alamat) === 3" style="padding-left: 1.8rem;">
                           Di&nbsp;{{ singleDraft.memo.kepada_line_3 }}
                        </span>


                     </td>
                   </tr>
                   <tr v-if="singleDraft.memo.tipe === 'eksternal'">
                     <td>{{ $t("label.carbon-copy") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                        <ol>
                        <li v-for="(item,index) in singleDraft.tembusan" :key="index">
                          <span style="display: block; width: 100%;">{{ item }}</span>
                        </li>
                      </ol>
                     </td>
                   </tr>
                   <tr v-else>
                     <td>{{ $t("label.carbon-copy") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                       <v-chip
                         v-for="item,idx in singleDraft.tembusan"
                         :key="idx"
                         class="mr-1 mb-1 recipient__chip"
                       >
                         {{item.nama}}
                       </v-chip>
                     </td>
                   </tr>
                   <tr>
                     <td colspan="3" style="height: 20px">&nbsp;</td>
                   </tr>
                   <tr v-if="singleDraft.memo.tipe === 'eksternal' || singleDraft.memo.tipe === 'bom'">
                     <td>{{ $t("label.approver x letter") }}</td>
                     <td>:</td>
                     <td>
                       <strong class="text-bolder blue--text"
                         >{{
                           singleDraft.memo.approver_name
                             ? singleDraft.memo.approver_name
                             : ""
                         }}
                       </strong>
                       <br />
                       {{
                         singleDraft.pengirim
                           ? '('+singleDraft.pengirim.name+')'
                           : ""
                       }}
                     </td>
                   </tr>
                   <tr>
                     <td colspan="3" style="height: 20px">&nbsp;</td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.draft-status") }}</td>
                     <td>:</td>
                     <td>
                       {{ singleDraft ? singleDraft.memo.memo_status_label : "" }}
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.created-by") }}</td>
                     <td>:</td>
                     <td>
                       {{ singleDraft ? singleDraft.memo.creator_name : "" }}
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.created-at") }}</td>
                     <td>:</td>
                     <td>
                       {{
                         singleDraft
                           ? indoDate(singleDraft.memo.created_at)
                           : ""
                       }}
                     </td>
                   </tr>
                   <tr>
                     <td colspan="3" style="height: 20px">&nbsp;</td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.last-reviser") }}</td>
                     <td>:</td>
                     <td>
                       {{
                         singleDraft
                           ? singleDraft.memo.nama_reviser_terakhir
                           : ""
                       }}
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.last-revised-at") }}</td>
                     <td>:</td>
                     <td>
                       {{
                         singleDraft
                           ? indoDate(singleDraft.waktu_reviser_terakhir)
                           : ""
                       }}
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t("label.reviser-list") }}</td>
                     <td>:</td>
                     <td>
                        <a
                         href="javascript:;"
                         v-for="(item, idx) in singleDraft.reviser_list"
                         :key="idx"
                         class="d-block reviser__link"
                         @click="showRevisionDetails(item.id_hash, item.nama)"
                       >
                         <v-avatar left class="reviser__avatar-icon">
                           <v-icon class="blue--text text--lighten-2">{{
                             iconUser
                           }}</v-icon>
                         </v-avatar>
                         <span class="reviser__link-name">
                           {{ `${idx + 1}. ${item.nama}` }}
                         </span>
                       </a>
                     </td>
                   </tr>
                 </table>
               </div>
             </div>
           </v-card-text>
         </v-card>
       </v-col>
       <v-col md="6" sm="12" class="d-flex flex-column">
         <v-card class="flex" outlined tile>
           <v-list-item two-line>
             <v-list-item-content>
               <v-list-item-title class="headline">
                 {{ $t("card.revision-tracking") }}
               </v-list-item-title>
               <v-list-item-subtitle>{{
                 $t("text.traces-the-concept")
               }}</v-list-item-subtitle>
             </v-list-item-content>
           </v-list-item>
           <v-card-text class="mx-2">
             <div class="table-responsive max-h-500" v-if="trackingList">
               <div>
                 <ul class="history-awb-timeline">
                   <li v-for="(item, index) in trackingList" :key="index">
                     <div class="d-flex flex-row">
                       <div
                         class="history-date d-flex flex-column"
                         v-if="isUniqueDate(index)"
                       >
                         <p>{{ mydate(item.created_at, "dayname") }},</p>
                         <h5>{{ mydate(item.created_at, "date") }}</h5>
                       </div>
                       <div class="history-date d-flex flex-column" v-else>
                         <p></p>
                         <h5></h5>
                       </div>
                       <div class="history-detail d-flex flex-column">
                         <p style="margin: 0px">
                           {{ mydate(item.created_at, "time") }}
                         </p>
                         <p style="margin: 0px" v-html="item.action"></p>
                         <p v-html="item.desc"></p>
                       </div>
                     </div>
                   </li>
                 </ul>
               </div>
             </div>
           </v-card-text>
           <v-card-actions class="pa-4">
             <v-spacer></v-spacer>
             <v-btn
               outlined
               color="blue lighten-2"
               @click="showHideLogDialog(true)"
               data-hintposition="middle-middle"
             >
               <v-icon class="mr-2">{{ iconLog }}</v-icon>
               {{ $t("button.log-detail") }}
             </v-btn>
           </v-card-actions>
         </v-card>
       </v-col>
     </v-row>
     <log-modal
       v-bind:showLogDialog="showLogDialog"
       v-bind:memoId="singleDraft.memo.id_hash"
       v-on:showHideLogDialog="showHideLogDialog"
     ></log-modal>
     <revision-info-modal
       v-bind:showRevInfoDialog="showRevInfoDialog"
       v-bind:memoRevId="memoRevId"
       v-bind:reviserName="reviserName"
       v-on:showHideRevInfoDialog="showHideRevInfoDialog"
     ></revision-info-modal>
   </div>
 </template>
 <script>
 import { mapState, mapActions } from "vuex";
 import {
   mdiAccount,
   mdiAccountGroup,
   mdiInformation,
   mdiCalendarTextOutline,
 } from "@mdi/js";
 import LogModal from "@/components/commonComponents/LogModal.vue";
 import RevisionInfoModal from "@/components/commonComponents/RevisionInfoModal.vue";
 export default {
   name: "history-detail",
   components: {
     LogModal,
     RevisionInfoModal,
   },
 
   data: () => ({
     iconUser: mdiAccount,
     iconGroup: mdiAccountGroup,
     iconInfo: mdiInformation,
     iconLog: mdiCalendarTextOutline,
     showLogDialog: false,
     showRevInfoDialog: false,
     memoRevId: undefined,
     reviserName: undefined,
   }),
   computed: {
     ...mapState("draft", [
       "singleDraftHistory",
       "singleDraft",
     ]),
     trackingList() {
       if (!this.singleDraftHistory) return undefined;
       return this.singleDraftHistory;
     },
     recipients() {
       let result = [];
       if (!this.singleDraft && !this.singleDraft) return result;
 
       if (this.singleDraft.penerima) result = this.singleDraft.penerima;
       if (this.singleDraft.penerima) result = this.singleDraft.penerima;
       return result;
       // let result = this.singleDraftInfo.penerima.map(({ name }) => name);
       // return result.join();
     },
     carbonCopy() {
       if (!this.singleDraft) return undefined;
 
       if (
         this.singleDraft.memo.tipe === 'eksternal' &&
         this.singleDraft.memo.tembusan_surat_eksternal
       ) {
         var rv = [];
         let item = {};
         let arr = this.singleDraft.memo.tembusan_surat_eksternal.split(",");
         for (var i = 0; i < arr.length; ++i) {
           item = { id: i, name: arr[i] };
           rv.push(item);
         }
         return rv;
       }
       return this.singleDraft.tembusan;
     },
     revisers() {
       if (!this.singleDraft) return undefined;
       return this.singleDraft.revisers;
     },
   },
   methods: {
     ...mapActions("draft", [
       "setDraftHistory",
       "getDraftHistory",
       "setDraftLog",
       "setsingleDraftInfo",
     ]),
     isUniqueDate(trackingIndex) {
       return this.checkDateTrackingItem(trackingIndex);
     },
     showHideLogDialog(val) {
       this.showLogDialog = val;
       if (!val) {
         this.setDraftLog(undefined);
       }
     },
     showHideRevInfoDialog(val) {
       this.showRevInfoDialog = val;
       if (!val) {
         this.memoRevId = undefined;
         this.reviserName = undefined;
         this.setsingleDraftInfo(undefined);
       }
     },
     showRevisionDetails(revId, reviserName) {
       if (!revId) return;
       this.memoRevId = {
         memoId: this.singleDraft.memo.id_hash,
         revisionId: revId,
       };
       this.reviserName = reviserName;
       this.showRevInfoDialog = true;
     },
     checkDateTrackingItem(trackingIndex) {
       const previousItem = this.singleDraftHistory[trackingIndex - 1];
       // const nextItem = this.trackingItems[trackingIndex + 1];
       const currentItem = this.singleDraftHistory[trackingIndex];
       if (!previousItem) {
         return true;
       }
       const prevDate = this.mydate(previousItem.created_at, "date");
       // const nextDate = nextItem ? nextItem.date : null;
       const currDate = this.mydate(currentItem.created_at, "date");
 
       if (prevDate !== currDate) return true;
       return false;
     },
     isTerminated(val) {
       return [19.29, 39, 49, 59].includes(parseInt(val));
     },
   },
 };
 </script>
 